import React from "react";

interface IconHomeProps {
  size?: number;
}

const IconHome: React.FC<IconHomeProps> = ({ size = 47 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_545_2311)" />

        <g transform="translate(9 9)">
            <g clipPath="url(#clip0)">
                <path
                    d="M12.4592 1.66031C12.3261 1.55642 12.1621 1.5 11.9932 1.5C11.8243 1.5 11.6603 1.55642 11.5272 1.66031L0.75 10.0645L1.68203 11.2433L3 10.2156V19.5C3.00081 19.8975 3.15911 20.2786 3.44024 20.5597C3.72137 20.8409 4.10242 20.9991 4.5 21H19.5C19.8976 20.9992 20.2787 20.841 20.5599 20.5598C20.841 20.2787 20.9993 19.8976 21 19.5V10.2225L22.318 11.25L23.25 10.0711L12.4592 1.66031ZM13.5 19.5H10.5V13.5H13.5V19.5ZM15 19.5V13.5C14.9995 13.1023 14.8414 12.721 14.5602 12.4398C14.279 12.1586 13.8977 12.0004 13.5 12H10.5C10.1023 12.0004 9.721 12.1585 9.43978 12.4397C9.15856 12.721 9.0004 13.1023 9 13.5V19.5H4.5V9.04608L12 3.20358L19.5 9.05396V19.5H15Z"
                    fill="white"
                />
            </g>
        </g>

        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default IconHome;
