import React, {createContext, useEffect, ReactNode, useState} from "react";
import {useLogto} from "@logto/react";
import {authStore} from "../store/authStore";
import {useCheckTokenExpiration} from "../hooks/useCheckTokenExpiration";
import {useNavigate} from "react-router-dom";

export interface AuthContextType {
    isAuthenticated: boolean,
    userLanguage: string,
    subscriptionData: SubscriptionData | null;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: ReactNode;
}

interface SubscriptionData {
    subscription: { period_end: string, period_start: string, type: string } | null;
    stripe_customer_id: string;
    first_customer: boolean;
}

interface CustomData {
    language?: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const {isAuthenticated, getIdTokenClaims, signOut, getAccessToken, fetchUserInfo} = useLogto();
    const {setUserName} = authStore.getState();
    const {isTokenValid, timeUntilExpiration} = useCheckTokenExpiration();
    const [userLanguage, setUserLanguage] = useState<string>(''); // Cambiado a string
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null); // Para almacenar los datos de suscripción
    /*const [redirected, setRedirected] = useState(false);*/
    const navigate = useNavigate()

    useEffect(() => {
        const checkTokenAndSignOut = async () => {
            if (isAuthenticated) {

                // Comprueba si el token es válido
                if (!isTokenValid) {
                    console.warn("El token no es válido. Cerrando sesión...");
                    await signOut(window.location.origin + "/"); // Cierra sesión si el token no es válido
                    return; // Salir de la función después de cerrar sesión
                }

                // Si el token es válido, verifica el tiempo restante
                const claims = await getIdTokenClaims();
                const userInfo = await fetchUserInfo();
                const customData = userInfo?.custom_data as CustomData;
                const preferredLanguage = customData?.language || navigator.language || navigator.languages[0];
                const normalizedLanguage = preferredLanguage.includes("-ES") ? "es" : preferredLanguage.split("-")[0] === "es" ? "es" : "en";

                setUserName(claims?.name || "");
                setUserLanguage(normalizedLanguage);

                // Si el tiempo hasta la expiración es menor a 60 segundos, cierra sesión
                if (timeUntilExpiration !== null && timeUntilExpiration < 60) {
                    console.warn("El token está a punto de expirar, cerrando la sesión...");
                    await signOut(window.location.origin + "/"); // Redirige a la raíz después de cerrar sesión
                }
            }
        };

        // Llama a la función para comprobar el token
        checkTokenAndSignOut().then();
        const interval = setInterval(checkTokenAndSignOut, 10000); // Comprueba el token cada 10 segundos

        return () => clearInterval(interval); // Limpia el intervalo cuando se desmonta
    }, [isAuthenticated, getIdTokenClaims, setUserName, timeUntilExpiration, signOut, isTokenValid, userLanguage, fetchUserInfo]);

    useEffect(() => {
        const fetchData = async () => {

            if (isAuthenticated) {
                try {
                    const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID);
                    const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/user/subscription`, {
                        method: "GET",
                        headers: {Authorization: `Bearer ${token}`}
                    });

                    const data = await response.json();
                    setSubscriptionData(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }

        };

        fetchData().then();

    }, [getAccessToken, getIdTokenClaims, isAuthenticated]);

    useEffect(() => {
        if (subscriptionData?.first_customer && !subscriptionData.subscription) {
            navigate("/subscription");
        }
    }, [subscriptionData, navigate]);

    return (
        <AuthContext.Provider value={{isAuthenticated, userLanguage, subscriptionData}}>
            {children}
        </AuthContext.Provider>
    );
};
