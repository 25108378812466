import React from "react";

const Logo: React.FC<{
    width?: number;
    height?: number;
    color?: string;
}> = ({width = 164, height = 42}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 165 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4230_9782)">
                <path
                    d="M45.1504 37V2.08793H59.3105C66.4395 2.08793 70.0039 5.20479 70.0039 11.4385C70.0039 18.2256 65.1292 22.3679 55.3799 23.8653L54.4521 20.0567C61.6136 18.9987 65.1943 16.1911 65.1943 11.6338C65.1943 7.76013 63.0947 5.82328 58.8955 5.82328H49.667V37H45.1504ZM80.2822 2.08793V37H75.7656V2.08793H80.2822ZM86.6543 29.2608C86.6543 24.0036 90.1211 21.375 97.0547 21.375C99.2845 21.375 101.514 21.5378 103.744 21.8633V19.4463C103.744 16.5004 101.441 15.0274 96.835 15.0274C94.1982 15.0274 91.4964 15.4343 88.7295 16.2481V12.586C91.4964 11.7722 94.1982 11.3653 96.835 11.3653C104.452 11.3653 108.261 14.0183 108.261 19.3243V37H105.722L104.159 34.461C101.604 36.1537 98.8206 37 95.8096 37C89.7061 37 86.6543 34.4203 86.6543 29.2608ZM97.0547 25.0371C93.1322 25.0371 91.1709 26.4206 91.1709 29.1875C91.1709 31.9545 92.7171 33.3379 95.8096 33.3379C98.9346 33.3379 101.579 32.5079 103.744 30.8477V25.5254C101.514 25.1999 99.2845 25.0371 97.0547 25.0371ZM112.558 11.3653H117.465L124.716 31.3848L132.211 11.3653H136.996L126.352 37.7569C124.464 42.4118 121.632 45.3171 117.855 46.4727L116.122 43.2745C118.987 42.0212 121.07 39.9786 122.372 37.1465L112.558 11.3653Z"
                    fill="#1D1D1B"/>
                <path
                    d="M15.5334 22.4395C17.043 22.4395 18.2667 21.2158 18.2667 19.7062C18.2667 18.1966 17.043 16.9729 15.5334 16.9729C14.0238 16.9729 12.8 18.1966 12.8 19.7062C12.8 21.2158 14.0238 22.4395 15.5334 22.4395Z"
                    fill="#3A7684"/>
                <path
                    d="M22.3666 22.4395C23.8762 22.4395 25.1 21.2158 25.1 19.7062C25.1 18.1966 23.8762 16.9729 22.3666 16.9729C20.8571 16.9729 19.6333 18.1966 19.6333 19.7062C19.6333 21.2158 20.8571 22.4395 22.3666 22.4395Z"
                    fill="#3A7684"/>
                <path
                    d="M28.5167 22.4395C29.6489 22.4395 30.5667 21.2158 30.5667 19.7062C30.5667 18.1966 29.6489 16.9729 28.5167 16.9729C27.3845 16.9729 26.4667 18.1966 26.4667 19.7062C26.4667 21.2158 27.3845 22.4395 28.5167 22.4395Z"
                    fill="#3A7684"/>
                <path
                    d="M30.8726 29.2988C29.8306 28.5137 28.3456 28.5873 27.4497 29.5392C26.6025 30.4371 25.6287 31.1977 24.5283 31.8306C22.7658 32.8414 20.8328 33.3467 18.7245 33.3467C17.0107 33.3467 15.4088 32.9984 13.9091 32.3016C12.4095 31.6049 11.0997 30.6579 9.98474 29.4656C8.86488 28.2733 7.97873 26.8798 7.31655 25.2852C6.65924 23.6905 6.32815 21.983 6.32815 20.1578C6.32815 18.3325 6.65924 16.625 7.31655 15.0304C7.97386 13.4406 8.86488 12.0471 9.98474 10.8499C11.1046 9.65762 12.4144 8.71065 13.9091 8.01391C15.4088 7.31717 17.0107 6.9688 18.7245 6.9688C20.8328 6.9688 22.7658 7.47418 24.5283 8.48494C25.6287 9.1179 26.6025 9.87842 27.4497 10.7763C28.3505 11.7233 29.8306 11.8018 30.8726 11.0168C32.1775 10.0354 32.2895 8.10713 31.116 6.9688C29.8891 5.77649 28.5014 4.76573 26.9433 3.93161C24.4553 2.60682 21.7189 1.93952 18.7197 1.93952C16.2511 1.93952 13.9286 2.42037 11.757 3.38207C9.58549 4.34376 7.69146 5.64402 6.07983 7.28773C4.46821 8.93145 3.19254 10.8548 2.25283 13.0579C1.31312 15.2659 0.845696 17.626 0.845696 20.148C0.845696 22.67 1.31312 25.0349 2.25283 27.238C2.66669 28.2095 3.15359 29.1172 3.69891 29.9808L0.5 40.2062L11.4698 36.7863C11.5672 36.8304 11.6597 36.8844 11.7619 36.9286C13.9335 37.8903 16.256 38.3711 18.7245 38.3711C21.7189 38.3711 24.4602 37.7087 26.9482 36.379C28.5014 35.5498 29.8939 34.5391 31.1209 33.3418C32.2943 32.2035 32.1824 30.2752 30.8775 29.2939L30.8726 29.2988Z"
                    fill="#3A7684"/>
            </g>
            <defs>
                <clipPath id="clip0_4230_9782">
                    <rect width="164" height="40.7128" fill="white" transform="translate(0.5 0.643593)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
