const colors = {
    primary: {
        50: "#e3f2f9",
        100: "#c5e4f3",
        500: "#062B60",
        600: "#0C56C0",
        700: "#0A48A1",
    },
    secondary: {
        500: "#38ADC7",
        700: "#2D9DB2",
    },
    blue: {
        50: "#38ADC7",
        100: "#0C56C0",
        500: "#094192",
        600: "#062B60",
        700: "#094192",
        800: "#051a39"
    },
    grey: {
        50: "#F7F7F7",            //background
        100: "#999",              //letter icons
        500: "#ECECEC",
        600: "#4E4D4D",         //background active menu
    },
    white: {
        50: "#FFF"
    },
    green: {
        100: "#34C759",
        300: "#269642"
    },
    black: {
        300: "#000000"
    }
};
  
export default colors;
  