import React from "react";

interface IconWalletProps {
  color?: string;
  width?: number;
  height?: number;
}

const IconWallet: React.FC<IconWalletProps> = ({ color = "#999999", width = 24 , height = 25}) => (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="wallet 1" clipPath="url(#clip0_71_576)">
            <path id="Vector" d="M18 13.25H16.5V14.75H18V13.25Z" fill={color} />
            <path id="Vector_2" d="M21 6.5H3V4.25H19.5V2.75H3C2.60218 2.75 2.22064 2.90804 1.93934 3.18934C1.65804 3.47064 1.5 3.85218 1.5 4.25V20C1.5 20.3978 1.65804 20.7794 1.93934 21.0607C2.22064 21.342 2.60218 21.5 3 21.5H21C21.3978 21.5 21.7794 21.342 22.0607 21.0607C22.342 20.7794 22.5 20.3978 22.5 20V8C22.5 7.60218 22.342 7.22064 22.0607 6.93934C21.7794 6.65804 21.3978 6.5 21 6.5ZM3 20V8H21V10.25H15C14.6022 10.25 14.2206 10.408 13.9393 10.6893C13.658 10.9706 13.5 11.3522 13.5 11.75V16.25C13.5 16.6478 13.658 17.0294 13.9393 17.3107C14.2206 17.592 14.6022 17.75 15 17.75H21V20H3ZM21 11.75V16.25H15V11.75H21Z" fill={color}/>
        </g>
        <defs>
            <clipPath id="clip0_71_576">
                <rect width={width} height={height} fill={color} />
            </clipPath>
        </defs>
    </svg>
);

export default IconWallet;
