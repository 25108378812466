// src/theme/theme.ts

import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import fonts from "./fonts/fonts";
import styles from "./styles";
import colors from "./colors";

const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    colors,
    fonts,
    styles,
});

export default theme;
