//
//
//  Pricing
//
//

import {useLogto} from "@logto/react"
import {useEffect, useState} from "react"


const LoadPotioScript = () => {
    // We need to load the Potio script in order to use the Potio web component
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://webcomponent.potio.cc/index.js"
        script.async = true
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return null
}


function Pricing() {
    const [isLoading, setIsLoading] = useState(true)
    const { signOut, getAccessToken, getIdTokenClaims } = useLogto()
    const [userId, setUserId] = useState<string | undefined>(undefined)
    const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
    const [stripeCustomer, setStripeCustomer] = useState<string | undefined>(undefined)

    useEffect(() => {
        const getStripeCustomerPromise = getAccessToken(import.meta.env.VITE_LOGTO_API_ID)
            .then(token => {
                return fetch(import.meta.env.VITE_API_ENDPOINT + "/api/user/subscription", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            }).then(response => response.json()).then(data => {
                setStripeCustomer(data["stripe_customer_id"])
            })

        const getUserInformation = getIdTokenClaims()
            .then(claims => {
                setUserId(claims?.sub)
                setUserEmail(claims?.email || undefined)
            })

        Promise.all([getStripeCustomerPromise, getUserInformation])
            .finally(() => {
                setIsLoading(false)
            })
    }, [getAccessToken, getIdTokenClaims])

    if (isLoading) {
        return <p>Loading ...</p>
    }

    return (
        <>
            <h1>Pricing</h1>
            <button onClick={() => signOut(window.location.origin + "/")}>Sign Out</button>

            {stripeCustomer ? (
                <potio-pricing-table
                    customer={stripeCustomer}
                    client-reference-id={userId}
                    action="payment"
                    data-key={import.meta.env.VITE_POTIO_PRICING_TABLE_KEY}
                ></potio-pricing-table>
            ) : (
                <potio-pricing-table
                    customer-email={userEmail}
                    client-reference-id={userId}
                    action="payment"
                    data-key={import.meta.env.VITE_POTIO_PRICING_TABLE_KEY}
                ></potio-pricing-table>
            )}
                
            <LoadPotioScript />
        </>
    )
}

export default Pricing
