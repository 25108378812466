import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion"; 
import SelectInput from "../../../../ui/Components/SelectInput"; 
import TextAreaInput from "../../../../ui/Components/TextAreaInput"; 
import ActionButton from "../../../../ui/Components/Button";
import { memo, useState } from "react";
import GenerativeAILogo from '../../../../icons/brain/generativeai';

const GenerativeAI = memo(() => {
    const [selectedOption, setSelectedOption] = useState("");
    const [textAreaValue, setTextAreaValue] = useState("");
    console.log('aqui', selectedOption)
    return (
        <Box
            maxWidth="670px"
            height="100vh"
            w="670px"
            mx="auto"
            py="100px"
            borderRadius="md"
            color="white"
            px="10px"
        >
            <Stack alignItems="flex-start">
                <Flex mb="46.5px" alignItems="center">
                    <Flex mr="52px" >   
                        <MainTitle text="BRAIN" />
                    </Flex>
                    <Flex alignItems="center" > 
                        <Subtitle text="GENERATIVE AI"  icon={<GenerativeAILogo width={24} height={24} color="#FFF" />}  />
                    </Flex>
                </Flex>
               
                <Text fontWeight="bold" fontSize="20px" mb="33px">
                Try the different Generative AI that your product can work with.
                </Text>
                <SelectInput
                    placeholder="Choose an option"
                    options={["Provider 1", "Provider 2", "Provider 3"]}
                    onChange={setSelectedOption}
                    label="Select provider"
                />
                <TextAreaInput
                    placeholder="You are a helpful assistant"
                    value={textAreaValue}
                    onChange={setTextAreaValue}
                    label="AI Guidelines"
                />
                <ActionButton text="Continue" onClick={() => console.log("Continue clicked")} />
            </Stack>
        </Box>
    );
});

export default GenerativeAI;
