export const applyRevealEffect = (text: string, callback: (visibleText: string) => void) => {
    let index = 0;
    const interval = setInterval(() => {
        callback(text.slice(0, index + 1));
        index += 1;
        if (index >= text.length) {
            clearInterval(interval);
        }
    }, 20);
};
