import { Button } from "@chakra-ui/react";
import React from "react";

interface ActionButtonProps {
  text: string;
  onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, onClick }) => {
    return (
        <Button 
            colorScheme="blue" 
            bg="blue.700"
            onClick={onClick} 
            _hover={{ bg: "blue.600" }}
        >
            {text}
        </Button>
    );
};

export default ActionButton;
