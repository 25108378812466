import {useHandleSignInCallback} from "@logto/react";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignInCallback() {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const { error } = useHandleSignInCallback(() => {
        navigate("/")
    });

    if(error) {
        return <div>{t("errorAuthenticating")}</div>
    }

    return null;
}

export default SignInCallback
