import React from "react";

const GenerativeAILogo: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 24, height = 24, color = "#FFF" }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="generative_ai" clipPath="url(#clip0_2730_16604)">
                <path
                    id="Vector"
                    d="M14.25 16.5V15H15V9.75H14.25V8.25H17.25V9.75H16.5V15H17.25V16.5H14.25Z"
                    fill={color}
                />
                <path
                    id="Vector_2"
                    d="M11.6253 16.5H13.1253L10.4999 8.25H8.24989L5.62744 16.5H7.12654L7.57789 15H11.1615L11.6253 16.5ZM8.02924 13.5L9.25054 9.44167L9.44247 9.43988L10.6977 13.5001L8.02924 13.5Z"
                    fill={color}
                />
                <path
                    id="Vector_3"
                    d="M24 3H21V0H19.5V3H16.5V4.5H19.5V7.5H21V4.5H24V3Z"
                    fill={color}
                />
                <path
                    id="Vector_4"
                    d="M24 9H22.5V10.5H24V9Z"
                    fill={color}
                />
                <path
                    id="Vector_5"
                    d="M15 0H13.5V1.5H15V0Z"
                    fill={color}
                />
                <path
                    id="Vector_6"
                    d="M24 24H0V0H10.5V1.5H1.5V22.5H22.5V13.5H24V24Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_2730_16604">
                    <rect width="24" height="24" fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GenerativeAILogo;
