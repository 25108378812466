import { Select, Text,Flex } from "@chakra-ui/react";
import React from "react";

interface SelectInputProps {
  placeholder: string;
  options: string[];
  onChange: (value: string) => void;
  label?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({ placeholder, options, onChange, label }) => {
    return (
        <Flex mb="15px" width="100%" alignItems="flex-start" flexDir="column">
            {label && (
                <Text mb="7px" fontSize="16px" color="white.50" fontWeight="normal">
                    {label}
                </Text>
            )}
            <Select 
                bg="white.50"
                color="black.300" 
                placeholder={placeholder} 
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
        </Flex>
    );
};

export default SelectInput;
