import { HomeMenu, IconRobot, IconAssist  } from "../../../../icons";

const getIconForName = (name: string) => {
    switch (name) {
    case "Hub":
        return <HomeMenu />;
    case "Play":
        return <IconRobot />;
    case "Assist":
        return <IconAssist />;
    case "Elevate":
        return <IconRobot />;
    case "Talent":
        return <IconRobot />;
    case "Collet":
        return <IconRobot />;
    case "Ad":
        return <IconRobot />;
    default:
        return <IconRobot />;
    }
};
  

export { getIconForName }