import React from "react";
import { VStack, Box } from "@chakra-ui/react";
import { useToken } from "@chakra-ui/react";
import { Wallet, Catalog } from "../../../../icons";
import { useLocation, Link } from "react-router-dom";

const LittleMenu: React.FC = () => {
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;

    const [activeColor, inactiveColor] = useToken("colors", ["blue.50", "grey.100"]);

    return (
        <VStack align="start" spacing={4} p={4} pt="100px">
            <Link to="/home">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={ isActive("/") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>

            <Link to="/subscription">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/plan-and-billing") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Wallet color={ isActive("/subscription") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>

            {/* Rutas de Play */}
            <Link to="/play/brain/generative-ai">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/brain/generative-ai") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/brain/generative-ai") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/brain/sentiment-analysis">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/brain/sentiment-analysis") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/brain/sentiment-analysis") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/ear/understanding">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/ear/understanding") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/ear/understanding") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/ear/voice">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/ear/voice") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/ear/voice") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/ear/voice-biometry">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/ear/voice-biometry") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/ear/voice-biometry") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/eyes/image-generation">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/eyes/image-generation") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/eyes/image-generation") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/eyes/image-interaction">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/eyes/image-interaction") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/eyes/image-interaction") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/mouth/ai-voices">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/mouth/ai-voices") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/mouth/ai-voices") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
            <Link to="/play/mouth/voice-cloning">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt="10px"
                    pb="10px"
                    p="8px"
                    borderRadius="md"
                    width="100%"
                    bg={isActive("/play/mouth/voice-cloning") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/play/mouth/voice-cloning") ? activeColor : inactiveColor} width={30} height={30}/>
                </Box>
            </Link>
        </VStack>
    );
};

export default LittleMenu;
