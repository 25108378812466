import Navbar from "./Navbar";
import {MainContainer} from "./MainContainer";
import {Box, Flex, IconButton} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {useState} from "react";
import SidebarMenu from "./SidebarMenu";
import CustomBackgroundContainer from '../Components/CustomerBackgroundContainer'

export const Layout = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <>
            <Navbar/>
            <MainContainer>
                <Flex width="100%">
                    <Box width={isMenuOpen ? "250px" : "80px"} transition="width 0.4s ease" position="relative">
                        <SidebarMenu isMenuOpen={isMenuOpen} />
                        <IconButton
                            icon={isMenuOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                            onClick={toggleMenu}
                            position="absolute"
                            top="50px"
                            right="-15px"
                            size="sm"
                            aria-label="Toggle Menu"
                            bg="white"
                            boxShadow="md"
                            zIndex={2}
                        />
                    </Box>
                    <CustomBackgroundContainer>
                        <Outlet/>
                    </CustomBackgroundContainer>
                </Flex>
            </MainContainer>
        </>
    )
}
