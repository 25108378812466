import { Box } from "@chakra-ui/react";
import React from "react";
import girlImage from '../../../assets/imgs/background-girl.png';

interface CustomBackgroundContainerProps {
  children: React.ReactNode;
}

const CustomBackgroundContainer: React.FC<CustomBackgroundContainerProps> = ({ children }) => {
    return (
        <Box
            display="flex"
            position="relative"
            flex="1"
            pt="25"
            borderRadius="xl"
            minHeight="100vh"
            w="100%"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
        >
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                background="radial-gradient(50% 50% at 166% 50%, rgba(140, 140, 140, 0.9) 0%, rgba(99, 105, 106, 0.9) 100%)"
                zIndex="1"
            />

            <Box
                position="absolute"
                top="0"
                right="0"
                height="100%"
                width="45%" 
                backgroundImage={`url(${girlImage})`}
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                zIndex="2"
                sx={{
                    mixBlendMode: "lighten", 
                }}
            />
            <Box 
                position="relative"
                zIndex="3"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {children}
            </Box>
        </Box>
    );
};

export default CustomBackgroundContainer;
