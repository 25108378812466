import React, { useEffect, useState } from "react";
import { VStack, Box, Text } from "@chakra-ui/react";
import { useToken } from "@chakra-ui/react";
import { Wallet, Catalog } from "../../../icons";
import { useLocation, Link } from "react-router-dom";
import LittleMenu from "./littleMenu.tsx";
import { applyRevealEffect } from './utils.tsx';

interface SidebarMenuProps {
    isMenuOpen: boolean;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isMenuOpen }) => {
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;

    const [activeColor, inactiveColor] = useToken("colors", ["blue.50", "grey.100"]);
    const [visibleTexts, setVisibleTexts] = useState({
        home: "",
        subscription: "",
        generativeAi: "",
        sentimentAnalysis: "",
        understanding: "",
        voice: "",
        voiceBiometry: "",
        imageGeneration: "",
        imageInteraction: "",
        aiVoices: "",
        voiceCloning: ""
    });

    useEffect(() => {
        if (isMenuOpen) {
            applyRevealEffect("home", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, home: visibleText }))
            );
            applyRevealEffect("subscription", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, subscription: visibleText }))
            );
            // Aplicar efecto de revelado a las nuevas rutas de "play"
            applyRevealEffect("generativeAi", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, generativeAi: visibleText }))
            );
            applyRevealEffect("sentimentAnalysis", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, sentimentAnalysis: visibleText }))
            );
            applyRevealEffect("understanding", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, understanding: visibleText }))
            );
            applyRevealEffect("voice", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, voice: visibleText }))
            );
            applyRevealEffect("voiceBiometry", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, voiceBiometry: visibleText }))
            );
            applyRevealEffect("imageGeneration", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, imageGeneration: visibleText }))
            );
            applyRevealEffect("imageInteraction", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, imageInteraction: visibleText }))
            );
            applyRevealEffect("aiVoices", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, aiVoices: visibleText }))
            );
            applyRevealEffect("voiceCloning", (visibleText) =>
                setVisibleTexts((prev) => ({ ...prev, voiceCloning: visibleText }))
            );
        }
    }, [isMenuOpen]);

    if (!isMenuOpen) return <LittleMenu />;

    return (
        <VStack align="start" spacing={4} p={4} pt="110px">
            <Link to="/home" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/home") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Catalog color={isActive("/home") ? activeColor : inactiveColor} />
                    <Text
                        fontWeight="bold"
                        color="grey.100"
                        ml="11px"
                        fontSize="19px"
                        opacity={0.9}
                        transition="opacity 0.3s"
                    >
                        {visibleTexts.home}
                    </Text>
                </Box>
            </Link>
            <Link to="/subscription" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/subscription") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Wallet color={isActive("/subscription") ? activeColor : inactiveColor} />
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s" textAlign="start">
                        {visibleTexts.subscription}
                    </Text>
                </Box>
            </Link>

            {/* Rutas de Play */}
            <Link to="/play/brain/generative-ai" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/brain/generative-ai") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.generativeAi}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/brain/sentiment-analysis" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/brain/sentiment-analysis") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.sentimentAnalysis}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/ear/understanding" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/ear/understanding") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.understanding}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/ear/voice" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/ear/voice") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.voice}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/ear/voice-biometry" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/ear/voice-biometry") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.voiceBiometry}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/eyes/image-generation" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/eyes/image-generation") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.imageGeneration}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/eyes/image-interaction" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/eyes/image-interaction") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.imageInteraction}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/mouth/ai-voices" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/mouth/ai-voices") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.aiVoices}
                    </Text>
                </Box>
            </Link>
            <Link to="/play/mouth/voice-cloning" style={{ display: "block", minWidth: "100%" }}>
                <Box
                    display="flex"
                    alignItems="start"
                    pt="10px"
                    pb="10px"
                    px="15px"
                    borderRadius="md"
                    bg={isActive("/play/mouth/voice-cloning") ? "grey.500" : "transparent"}
                    _hover={{ bg: "grey.500", cursor: "pointer" }}
                >
                    <Text color="grey.100" ml="11px" fontSize="19px" opacity={0.9} transition="opacity 0.3s">
                        {visibleTexts.voiceCloning}
                    </Text>
                </Box>
            </Link>

        </VStack>
    );
};

export default SidebarMenu;
