import React from "react";

const Menu: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = () => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="hinton-plot 1" clipPath="url(#clip0_3937_23354)">
                <path id="Vector" d="M6.5 2H2.5V6H6.5V2Z" fill="#3A7684" />
                <path id="Vector_2" d="M14.5 2H10.5V6H14.5V2Z" fill="#3A7684" />
                <path id="Vector_3" d="M22.5 2H18.5V6H22.5V2Z" fill="#3A7684" />
                <path id="Vector_4" d="M30.5 2H26.5V6H30.5V2Z" fill="#3A7684" />
                <path id="Vector_5" d="M6.5 10H2.5V14H6.5V10Z" fill="#3A7684" />
                <path id="Vector_6" d="M14.5 10H10.5V14H14.5V10Z" fill="#3A7684" />
                <path id="Vector_7" d="M22.5 10H18.5V14H22.5V10Z" fill="#3A7684" />
                <path id="Vector_8" d="M30.5 10H26.5V14H30.5V10Z" fill="#3A7684" />
                <path id="Vector_9" d="M6.5 18H2.5V22H6.5V18Z" fill="#3A7684" />
                <path id="Vector_10" d="M14.5 18H10.5V22H14.5V18Z" fill="#3A7684" />
                <path id="Vector_11" d="M22.5 18H18.5V22H22.5V18Z" fill="#3A7684" />
                <path id="Vector_12" d="M30.5 18H26.5V22H30.5V18Z" fill="#3A7684" />
                <path id="Vector_13" d="M6.5 26H2.5V30H6.5V26Z" fill="#3A7684" />
                <path id="Vector_14" d="M14.5 26H10.5V30H14.5V26Z" fill="#3A7684" />
                <path id="Vector_15" d="M22.5 26H18.5V30H22.5V26Z" fill="#3A7684" />
                <path id="Vector_16" d="M30.5 26H26.5V30H30.5V26Z" fill="#3A7684" />
            </g>
            <defs>
                <clipPath id="clip0_3937_23354">
                    <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Menu;
