import { Flex, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface SubtitleSeccionProps {
  text: string;
  icon?: ReactElement;
}

const SubtitleSeccion: React.FC<SubtitleSeccionProps> = ({ text, icon }) => {
    return (
        <Flex alignItems="center">   
            {icon && <Flex mr="14px">{icon}</Flex>}
            <Text fontFamily="Lato-Bold"  fontSize="15px" fontWeight="semibold" color="white.50">
                {text}
            </Text>
        </Flex>
    );
};

export default SubtitleSeccion;
